<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增链接' : '编辑连接'" width="600px">
    <el-form ref="form" :model="form" size="small" label-width="90px">
      <el-form-item label="所属站点">
        <el-input v-model="form.siteId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="链接客户">
        <el-input v-model="form.siteName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="客户LOGO" prop="logoUrl">
        <el-upload
          class="upload-demo"
          :action="uploadUrl"
          :on-success="handleSuccess"
          :file-list="fileLists"
          list-type="picture">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <img v-if="!isAdd && form.logoUrl" :src="form.logoUrl" class="logo-class">
        <el-input :autosize="{ minRows: 2, maxRows: 4}" type="textarea" v-model="form.logoUrl" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="官网">
        <el-input v-model="form.domainName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="排序号">
        <el-input v-model="form.sortNo" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="行业">
        <el-input v-model="form.industryType" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="分组">
        <el-select v-model="form.showGroup" style="width: 370px;" >
                  <el-option
                    v-for="item in showGroups"
                    :key="item.value"
                    :label="item.key"
                    :value="item.value">
                  </el-option>
                </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/alitatech/srPortalSiteLink'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      showGroups:[
        {key:'我们的客户',value:1},
        {key:'友情链接',value:2},
        {key:'以上两者',value:3}
      ],
      form: {
        id: '',
        siteName: '',
        logoUrl: '',
        domainName: '',
        sortNo: '',
        industryType: '',
        showGroup: ''
      },
      fileLists:[],
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    handleSuccess(response){
        //上传成功要处理的事
        this.form.logoUrl=response.data.content[0].url;
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        siteName: '',
        logoUrl: '',
        domainName: '',
        sortNo: '',
        industryType: '',
        showGroup: '',
        createTime: '',
        updateTime: ''
      }
    }
  }
}
</script>

<style scoped>
  .logo-class{
  height: 100px;
  width: 200px;
  margin-top: 10px;
}
</style>
